import { fromJS, Map } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"
import * as AdminCategoriesStorage from "highline/storage/admin_categories_storage"
import { restoreExpanded } from "highline/redux/admin/helpers/resource_tree_helper"

const initialState = fromJS({
  error: Map(),
  isCreateModalVisible: false,
  list: [],
  newCategoryName: "",
  newCategorySlug: "",
})

const indexReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_CATEGORIES_CREATE_SUCCEEDED:
      return state.merge({
        createCategoryError: "",
        isCreateModalVisible: false,
        newCategoryName: "",
        newCategorySlug: "",
      })

    case AdminActionTypes.ADMIN_CATEGORIES_FETCH_ALL_SUCCEEDED: {
      const { expandedCategories } = AdminCategoriesStorage.load()
      const list = restoreExpanded(action.categories.toJS(), expandedCategories)
      return state.set("list", fromJS(list))
    }

    case AdminActionTypes.ADMIN_CATEGORIES_TREE_DATA_CHANGED:
      return state.set("list", fromJS(action.list))

    case AdminActionTypes.ADMIN_CATEGORIES_ADD_CHILD_CLICKED:
      return state.merge({ isCreateModalVisible: true })

    case AdminActionTypes.ADMIN_CATEGORIES_MODAL_CLOSE_CLICKED:
      return state.merge({
        createCategoryError: "",
        error: Map(),
        isCreateModalVisible: false,
        newCategoryName: "",
        newCategorySlug: "",
      })

    case AdminActionTypes.ADMIN_CATEGORIES_CREATE_CATEGORY_INPUT_CHANGED:
      return state.set(action.name, action.value)

    case AdminActionTypes.ADMIN_CATEGORIES_CREATE_FAILED:
      return state.set("error", action.error)

    default:
      return state
  }
}

export default indexReducer
