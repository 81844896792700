import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  deleteModal: { isOpen: false, id: null, name: null },
  errors: {},
  list: [],
})

const indexReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_DELETE_MODAL_CLOSED:
      return state.set("deleteModal", initialState.get("deleteModal"))

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_DELETE_MODAL_OPENED:
      return state.set("deleteModal", fromJS({
        isOpen: true,
        id: action.educationGroupId,
        name: action.educationGroupName,
      }))

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_DELETE_SUCCEEDED:
      return state.merge({
        deleteModal: fromJS({ isOpen: false }),
        errors: {},
      })

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_ALL_FAILED:
      return state.merge({
        errors: state.get("errors").merge({ index: "Something went wrong" }),
      })

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_ALL_SUCCEEDED:
      return state.set("list", action.educationGroups)

    default:
      return state
  }
}

export default indexReducer
