import React from "react"
import { Provider } from "react-redux"
import { fromJS } from "immutable"
import { extractQueryParams } from "highline/utils/url"
import { isServer } from "highline/utils/client"

const __NEXT_REDUX_STORE__ = "__NEXT_REDUX_STORE__"

const pageParamsFromRequest = (ctx) => {
  const proto = ctx.req.headers["x-forwarded-proto"] || "http" // Need to verify
  // eslint-disable-next-line compat/compat
  const urlObj = new URL(`${proto}://${ctx.req.headers.host}${ctx.asPath}`)

  // Not using provided ctx.query as it can contain potentially misleading entries for dynamic pages
  // Convert urlObj.searchParams to JS object
  const query = {}
  for (const [key, value] of urlObj.searchParams.entries()) {
    query[key] = value
  }

  return {
    origin: urlObj.origin,
    path: urlObj.pathname,
    query: fromJS(query),
    referrer: ctx.req.headers.referer,
    url: urlObj.href,
  }
}

const pageParamsFromWindow = () => ({
  origin: window.location.origin,
  path: window.location.pathname,
  query: fromJS(extractQueryParams(window.location.search)),
  referrer: document.referrer,
  url: window.location.href,
})

const pageParamsFromStatic = (App) => ({
  origin: "",
  path: App.WrappedComponent.canonicalPath,
  query: fromJS({}),
  referrer: "",
  url: "",
})

const getOrCreateStore = (createStoreFunc, initialState, storePageParam) => {
  if (isServer) {
    return createStoreFunc(initialState, storePageParam)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = createStoreFunc(initialState, storePageParam)
  }
  return window[__NEXT_REDUX_STORE__]
}

export default (createStoreFunc, App) => {
  return class AppWithRedux extends React.Component {
    static async getInitialProps(appContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const storePageParam = isServer ? pageParamsFromRequest(appContext): pageParamsFromWindow()
      const reduxStore = getOrCreateStore(createStoreFunc, {}, storePageParam)

      appContext.store = reduxStore

      let appProps = {}
      if (typeof App.getInitialProps === "function") {
        appProps = await App.getInitialProps(appContext)
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
        store: reduxStore,
        storePageParam,
      }
    }

    constructor(props) {
      super(props)
      this.reduxStore = getOrCreateStore(createStoreFunc, props.initialReduxState, props.storePageParam)
    }

    render() {
      return (
        <Provider store={ this.reduxStore }>
          <App { ...this.props } store={ this.reduxStore } />
        </Provider>
      )
    }
  }
}
