import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "highline/components/secure_link"
import Logo from "highline/svg/icons/bonobos-logo-dark.svg"
import NavGroup from "highline/components/nav_group"
import NavItem from "highline/components/nav_item"
import styles from "highline/styles/components/admin/header.module.css"
const { clientConfig } = require("../../commonjs/config")
const isStaging = clientConfig.isStaging

const AdminHeader = ({
  activePage,
  showLogo,
}) => {
  return (
    <div className={ classNames(
      "admin-header-component",
      styles.component,
    ) }>
      <header className={ classNames(
        styles.headerContent,
        isStaging && styles.stagingHeader,
      ) } role="banner">
        { showLogo &&
          <div className={ styles.logoContainer }>
            <Link href="/admin-new">
              <a>
                { isStaging ?
                  <div className={ styles.stagingLogoIcon }>
                    <div><Logo /></div>
                    <div className={ styles.stagingHeaderText }>Staging</div>
                  </div>
                  : <div className={ styles.logoIcon }>
                    <div><Logo /></div>
                  </div>
                }
              </a>
            </Link>
          </div>
        }

        <NavGroup
          className={ styles.nav }
          align="right"
          display="block"
          layout="link"
          activeItemName={ activePage }
          onChange={ () => {} }
        >
          <NavItem name="reset-variant-groups">
            <Link href="/admin-new/reset-variant-groups">
              <a>Reset Variant Groups</a>
            </Link>
          </NavItem>
          <NavItem name="categories">
            <Link href="/admin-new/categories">
              <a>Categories</a>
            </Link>
          </NavItem>
          <NavItem name="classifications">
            <Link href="/admin-new/classifications">
              <a>Classifications</a>
            </Link>
          </NavItem>
          <NavItem name="size-charts">
            <Link href="/admin-new/size-charts">
              <a>Size Charts</a>
            </Link>
          </NavItem>
          <NavItem name="education-groups">
            <Link href="/admin-new/education-groups">
              <a>Education Groups</a>
            </Link>
          </NavItem>
          <NavItem name="user-stock-locations">
            <Link href="/admin-new/user-stock-locations">
              <a>User Stock Locations</a>
            </Link>
          </NavItem>
          <NavItem name="navigation-items">
            <Link href="/admin-new/navigation-items">
              <a>Navigation Items</a>
            </Link>
          </NavItem>
        </NavGroup>
      </header>
    </div>
  )
}

AdminHeader.propTypes = {
  activePage: PropTypes.string,
  showLogo: PropTypes.bool,
}

AdminHeader.defaultProps = {
  showLogo: true,
}

export default AdminHeader
