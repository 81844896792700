import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  error: "",
  successMessage: "",
})

const adminResetVariantGroupsReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_RESET_VARIANT_GROUPS_SUCCEEDED:
      return state.set("successMessage", action.successMessage)
    case AdminActionTypes.ADMIN_RESET_VARIANT_GROUPS_FAILED:
      return state.set("error", action.error)
    default:
      return state
  }
}

export default adminResetVariantGroupsReducer
