import { redirect } from "highline/utils/navigate"

import AdminActionTypes from "highline/redux/admin/action_types"

const navigationMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_REDIRECT_FOUND: {
      if (action.redirectUrl) {
        redirect(action.redirectUrl)
        return
      }
      return next(action)
    }

    default:
      return next(action)
  }
}

export default navigationMiddleware
