import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"
import { buildOptionTypeMap } from "highline/utils/fit_preferences/helper"

const initialState = fromJS({
  isEditing: false,
  optionTypes: {},
  optionTypesLoading: true,
  userSelections: [],
  userSelectionsLoading: true,
})

const fitPreferencesReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_FIT_PREFERENCES_EDIT_TOGGLED:
      return state.set("isEditing", !state.get("isEditing"))

    case AdminActionTypes.ADMIN_FIT_PREFERENCES_OPTION_TYPES_FETCH_FAILED:
      return state.set("optionTypesLoading", false)

    case AdminActionTypes.ADMIN_FIT_PREFERENCES_USER_SELECTIONS_FETCH_FAILED:
      return state.set("userSelectionsLoading", false)

    case AdminActionTypes.ADMIN_FIT_PREFERENCES_OPTION_TYPES_FETCH_STARTED:
      return state.set("optionTypesLoading", true)

    case AdminActionTypes.ADMIN_FIT_PREFERENCES_USER_SELECTIONS_FETCH_STARTED:
      return state.set("userSelectionsLoading", true)

    case AdminActionTypes.ADMIN_FIT_PREFERENCES_OPTION_TYPES_FETCH_SUCCEEDED:
      return state.merge({
        optionTypes: buildOptionTypeMap(action.data),
        optionTypesLoading: false,
      })

    case AdminActionTypes.ADMIN_FIT_PREFERENCES_USER_SELECTIONS_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_FIT_PREFERENCES_USER_SELECTIONS_UPDATE_SUCCEEDED:
      return state.merge({
        userSelections: action.data,
        userSelectionsLoading: false,
      })

    default:
      return state
  }
}

export default fitPreferencesReducer
