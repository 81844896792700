import { fromJS, Map } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  category: {
    availableOn: null,
    description: "",
    id: null,
    isActive: false,
    isVisibleInNav: false,
    metaDescription: "",
    metaTitle: "",
    mobileImage: { alt: "", url: "" },
    name: "",
    narrative: "",
    parentId: null,
    parentCategories: [],
    primaryImage: { alt: "", url: "" },
    secondaryImage: { alt: "", url: "" },
    slug: "",
    subDescription: "",
  },
  errors: {},
  isDirty: false,
  isLoading: false,
})

export default function(state = initialState, action) {
  switch (action.type) {
    case AdminActionTypes.ADMIN_CATEGORY_REQUEST_STARTED:
      return state.set("isLoading", true)

    case AdminActionTypes.ADMIN_CATEGORY_REQUEST_COMPLETED:
      return state.set("isLoading", false)

    case AdminActionTypes.ADMIN_CATEGORIES_EDIT_CATEGORY_INPUT_CHANGED: {
      const category = state.get("category").set(action.name, action.value)
      const isDirty = !state.get("lastSaved").equals(category)

      return state.merge({ category, isDirty })
    }

    case AdminActionTypes.ADMIN_CATEGORY_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_CATEGORY_UPDATE_SUCCEEDED: {
      const availableOn = action.category.get("availableOn")
      const availableOnDate = availableOn ? new Date(availableOn) : null
      const category = action.category.set("availableOn", availableOnDate)
      return state.merge({
        category,
        errors: Map(),
        isDirty: false,
        lastSaved: category,
      })
    }

    case AdminActionTypes.ADMIN_CATEGORY_UPDATE_FAILED:
      return state.set("errors", action.errors)

    default:
      return state
  }
}
