export const getActivePage = (pathname) => {
  switch (true) {
    case pathname.includes("/admin-new/reset-variant-groups"):
      return "reset-variant-groups"

    case pathname.includes("/admin-new/categories"):
      return "categories"

    case pathname.includes("/admin-new/classifications"):
      return "classifications"

    case pathname.includes("/admin-new/size-charts"):
      return "size-charts"

    case pathname.includes("/admin-new/education-groups"):
      return "education-groups"

    case pathname.includes("/admin-new/ccpa-file-upload"):
      return "ccpa-file-upload"

    case pathname.includes("/admin-new/user-stock-locations"):
      return "user-stock-locations"

    default:
      return ""
  }
}
