import { GoogleOAuthProvider } from "@react-oauth/google"
import getConfig from "highline/config/application"
import classNames from "classnames"
import styles from "highline/styles/components/admin/admin_user_auth.module.css"
import UserAuthButton from "highline/components/admin/user_auth_button"
import { useDispatch } from "react-redux"
import {
  googleLoginClientAsync,
  googleLoginClientFailed,
} from "highline/redux/admin/actions/admin_layout_actions"

const { googleHostedDomain, googleLoginClientId } = getConfig()

const AdminUserAuth = () => {
  const dispatch = useDispatch()

  function handleGoogleLoginClientFailure(response) {
    dispatch(googleLoginClientFailed(response))
  }

  function handleGoogleLoginClientSuccess(response) {
    dispatch(googleLoginClientAsync(response))
  }

  return (
    <div className={ classNames(
      "admin-user-auth-component",
      styles.component,
    ) }>
      <GoogleOAuthProvider clientId={ googleLoginClientId }>
        <UserAuthButton
          hostedDomain={ googleHostedDomain }
          onGoogleLoginClientSuccess={ handleGoogleLoginClientSuccess }
          onGoogleLoginClientFailure={ handleGoogleLoginClientFailure }
        />

      </GoogleOAuthProvider>
    </div>
  )
}

export default AdminUserAuth
