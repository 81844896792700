import { List, Map, Set, fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  categoryId: null,
  categoryName: "",
  colorManagementSearchOptions: [],
  colorManagementSearchText: "",
  currentCategoryProgramId: null,
  excludedColors: [],
  filterType: "exclude",
  groups: [],
  includedColors: [],
  isFinalSale: null,
  isFullPrice: null,
  isNewOnly: null,
  isSale: null,
  programs: [],
  searchInputLastChangedAt: null,
  searchOptions: [],
  searchOptionsLoading: false,
  searchText: "",
  showColorManagementModal: false,
  slug: "",
})

export default function(state = initialState, action) {
  switch (action.type) {
    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_SEARCH_INPUT_CHANGED:
      return state.merge({
        searchInputLastChangedAt: action.timeNow,
        searchOptions: List(),
        searchText: action.searchText,
      })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_SUCCEEDED: {
      const searchOptions = action.programs
        .map((program) => Map({
          label: program.get("name"),
          value: { id: program.get("id"), type: program.get("type") },
        }))

      return state.merge({ searchOptions })
    }

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_STARTED:
      return state.merge({ searchOptions: List(), searchOptionsLoading: true })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_COMPLETED:
      return state.merge({ searchOptionsLoading: false })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_FAILED:
      return state.merge({ searchOptions: List() })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_LOAD_SUCCEEDED:
      return initialState.merge({
        categoryId: action.category.get("id"),
        categoryName: action.category.get("name"),
        isFinalSale: action.category.get("isFinalSale"),
        isFullPrice: action.category.get("isFullPrice"),
        isNewOnly: action.category.get("isNewOnly"),
        isSale: action.category.get("isSale"),
        programs: formatForSortableList(action.category.get("programs")),
        slug: action.category.get("path") + "/" + action.category.get("slug"),
      })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_CREATE_CATEGORY_PROGRAM_RULE_TYPE_SUCCEEDED:
    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_CREATE_CATEGORY_PROGRAM_RULE_SUCCEEDED:
    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_CATEGORY_PROGRAM_RULE_SUCCEEDED:
    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_CATEGORY_PROGRAM_SUCCEEDED: {
      const allColors = action.data.getIn(["programColors", "values"])

      const colorManagementSearchOptions = allColors
        .map((color) => Map({ label: color.get("presentation"), value: color.get("presentation") }))
        .sort((a,b) => a.get("value").localeCompare(b.get("value")))

      const includeOrExcludeRule = action.data.get("ruleTypes", List()).find(
        (ruleType) => ["include", "exclude"].includes(ruleType.get("ruleType")),
        null,
        Map(),
      )

      const filterType = includeOrExcludeRule.get("ruleType", "exclude")

      const ruleOptionValueIds = includeOrExcludeRule.get("rules", []).reduce(
        (set, rule) => set.add(rule.get("optionValueId")),
        Set(),
      )

      let colorsOnRule = List()
      let colorsNotOnRule = List()

      allColors.forEach((color) => {
        const colorHasRule = ruleOptionValueIds.get(color.get("optionValueId"))

        if (colorHasRule)
          colorsOnRule = colorsOnRule.push(color)
        else
          colorsNotOnRule = colorsNotOnRule.push(color)
      })

      let excludedColors = List()
      let includedColors = List()

      if (filterType == "exclude") {
        excludedColors = colorsOnRule
        includedColors = colorsNotOnRule
      } else {
        includedColors = colorsOnRule
        excludedColors = colorsNotOnRule
      }

      return state.merge({
        colorManagementSearchOptions,
        excludedColors,
        filterType,
        includedColors,
      })
    }

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_ADD_PROGRAM_SUCCEEDED:
    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_PROGRAM_SUCCEEDED:
    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_UPDATE_PROGRAM_SUCCEEDED:
      return state.merge({
        programs: formatForSortableList(action.categoryPrograms),
      })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_MANAGE_PROGRAM_CLICKED:
      return state.merge({
        currentCategoryProgramId: action.categoryProgramId,
        showColorManagementModal: true,
      })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FILTERS_UPDATE_SUCCEEDED:
      return state.merge(action.filterValues)

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_COLOR_MANAGEMENT_CLOSE_CLICKED:
      return state.merge({
        showColorManagementModal: false,
        colorManagementSearchText: "",
      })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_FILTER_TYPE_CHANGED:
      return state.set("filterType", action.filterType)

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_COLOR_MANAGEMENT_SEARCH_INPUT_CHANGED:{
      return state.merge( {
        colorManagementSearchText: action.searchText,
      } )
    }

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_COLOR_MANAGEMENT_SEARCH_SELECTED:
      return state.set("colorManagementSearchText", action.searchValue)

    default:
      return state
  }
}

// Sortable list requires a "name" field
function formatForSortableList(programs) {
  return programs.map(
    (program) => program.set("name", program.get("programName")),
  )
}
