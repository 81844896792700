import Lockr from "lockr"

const STORAGE_KEY = "adminNavigationItems"
const DEFAULTS = { expandedNavigationItems: [] /* Set */ }

// Convert expandedNavigationItems to Set when extracting
export const load = () => {
  const data = Lockr.get(STORAGE_KEY) || DEFAULTS
  const expandedNavigationItems = new Set(data.expandedNavigationItems)
  return { ...data, expandedNavigationItems }
}

// Convert expandedNavigationItems to Array when saving
export const save = (adminNavigationItems) => {
  const { expandedNavigationItems } = adminNavigationItems

  const data = {
    ...adminNavigationItems,
    expandedNavigationItems: Array.from(expandedNavigationItems),
  }

  Lockr.set(STORAGE_KEY, data)
}

export const expanded = (navigationItemId) => {
  const { expandedNavigationItems } = load()
  expandedNavigationItems.add(navigationItemId)
  save({ expandedNavigationItems })
}

export const collapsed = (navigationItemId) => {
  const { expandedNavigationItems } = load()
  expandedNavigationItems.delete(navigationItemId)
  save({ expandedNavigationItems })
}

export const clear = () => {
  Lockr.rm(STORAGE_KEY)
}
