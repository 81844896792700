import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  origin: "",
  path: "",
  query: {},
  referrer: "",
  url: "",
})

const currentPageReducer = (state = initialState, action)  => {
  switch (action.type) {

    case AdminActionTypes.ADMIN_CURRENT_PAGE_CHANGED:
      return state.merge({
        origin: action.origin,
        path: action.path,
        query: action.query || fromJS({}),
        referrer: action.referrer,
        url: action.url,
      })

    default:
      return state
  }
}

export default currentPageReducer
