import AdminActionTypes from "highline/redux/admin/action_types"
import * as AdminCategoriesStorage from "highline/storage/admin_categories_storage"
import * as AdminNavigationItemsStorage from "highline/storage/admin_navigation_items_storage"
import * as UserAuthStorage from "highline/storage/user_auth_storage"
import * as UserStorage from "highline/storage/user_storage"

const localStorageMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_CATEGORIES_VISIBILITY_TOGGLED: {
      if (action.expanded)
        AdminCategoriesStorage.expanded(action.node.id)
      else
        AdminCategoriesStorage.collapsed(action.node.id)
      break
    }

    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_VISIBILITY_TOGGLED: {
      if (action.expanded)
        AdminNavigationItemsStorage.expanded(action.node.id)
      else
        AdminNavigationItemsStorage.collapsed(action.node.id)
      break
    }

    case AdminActionTypes.ADMIN_USER_LOGGED_IN: {
      const {
        authenticationToken,
        email,
        externalId,
        firstName,
        lastName,
        userId,
      } = action

      UserAuthStorage.save({ authenticationToken, userId })
      UserStorage.save({ firstName, lastName, email, externalId })
      break
    }
  }

  return next(action)
}

export default localStorageMiddleware
