import { fromJS, Map } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"
import * as AdminNavigationItemsStorage from "highline/storage/admin_navigation_items_storage"
import { restoreExpanded } from "highline/redux/admin/helpers/resource_tree_helper"

const initialState = fromJS({
  errors: {},
  isCreateModalVisible: false,
  list: [],
  newNavigationItemName: "",
  newNavigationItemPath: "",
})

const indexReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_FETCH_ALL_SUCCEEDED: {
      const { expandedNavigationItems } = AdminNavigationItemsStorage.load()
      const list = restoreExpanded(action.navigationItems.toJS(), expandedNavigationItems)
      return state.set("list", fromJS(list))
    }

    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_TREE_DATA_CHANGED:
      return state.set("list", fromJS(action.list))

    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_ADD_CHILD_CLICKED:
      return state.merge({ isCreateModalVisible: true })

    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_CREATE_NAVIGATION_ITEM_INPUT_CHANGED:
      return state.set(action.name, action.value)

    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_MODAL_CLOSE_CLICKED:
      return state.merge({
        errors: Map(),
        isCreateModalVisible: false,
        newNavigationItemName: "",
        newNavigationItemPath: "",
      })

    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_CREATE_SUCCEEDED:
      return state.set("isCreateModalVisible", false)

    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_CREATE_FAILED:
      return state.set("errors", action.errors)

    default:
      return state
  }
}

export default indexReducer
