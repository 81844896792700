import { fromJS, Map } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  errors: {},
  isDirty: false,
  navigationItem: {
    alt: "",
    id: null,
    imageUrl: "",
    isActive: false,
    isVisibleDesktop: false,
    isVisibleMobile: false,
    label: "",
    parentId: null,
    parentNavigationItems: [],
    path: "",
    visibleAfter: null,
  },
})

export default function(state = initialState, action) {
  switch (action.type) {
    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_EDIT_NAVIGATION_ITEM_INPUT_CHANGED: {
      const navigationItem = state.get("navigationItem").set(action.name, action.value)
      const isDirty = !state.get("lastSaved").equals(navigationItem)

      return state.merge({ isDirty, navigationItem })
    }

    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_UPDATE_SUCCEEDED: {
      const visibleAfter = action.navigationItem.get("visibleAfter")
      const visibleAfterDate = visibleAfter ? new Date(visibleAfter) : null
      const navigationItem = action.navigationItem.set("visibleAfter", visibleAfterDate)
      return state.merge({
        errors: Map(),
        isDirty: false,
        lastSaved: navigationItem,
        navigationItem,
      })
    }

    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_UPDATE_FAILED:
      return state.set("errors", action.errors)

    default:
      return state
  }
}
