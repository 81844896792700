import * as AdminUsersApi from "highline/api/admin/users_api"
import AdminActionTypes from "highline/redux/admin/action_types"

export const userLoggedIn = (firstName, lastName, email, externalId, authenticationToken, userId) => ({
  type: AdminActionTypes.ADMIN_USER_LOGGED_IN,
  firstName,
  lastName,
  email,
  externalId,
  authenticationToken,
  userId,
})

export const userFetchStarted = () => ({
  type: AdminActionTypes.ADMIN_USERS_FETCH_STARTED,
})

export const userFetchSucceeded = (userDetails) => ({
  type: AdminActionTypes.ADMIN_USERS_FETCH_SUCCEEDED,
  userDetails,
})

export const userFetchFailed = (error) => ({
  type: AdminActionTypes.ADMIN_USERS_FETCH_FAILED,
  error,
})

export const userFetchCompleted = () => ({
  type: AdminActionTypes.ADMIN_USERS_FETCH_COMPLETED,
})

export const fetchUserDetailsAsync = (userId) => (
  async (dispatch, getState) => {
    const authToken = getState().getIn(["auth", "authenticationToken"])

    dispatch(userFetchStarted())

    try {
      const response = await AdminUsersApi.fetch(authToken, userId)
      return dispatch(userFetchSucceeded(response.data))
    } catch (error) {
      dispatch(userFetchFailed(error.data.get("errors")))
      setTimeout(() => { throw error })
    }

    dispatch(userFetchCompleted())
  }
)
