import { combineReducers } from "redux-immutable"
import editReducer from "highline/redux/admin/reducers/size_charts/edit_reducer"
import indexReducer from "highline/redux/admin/reducers/size_charts/index_reducer"

const rootReducer = combineReducers({
  edit: editReducer,
  index: indexReducer,
})

export default rootReducer
