import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  classificationId: null,
  classifications: [],
  isDirty: false,
  productId: null,
  productSlug: "",
  showDeleteModal: false,
})

const productClassificationsReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_DELETE_MODAL_TOGGLED:
      return state.set("showDeleteModal", !state.get("showDeleteModal"))

    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_DELETE_SUCCEEDED:
      return state.merge(initialState).set("classifications", state.get("classifications"))

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_ALL_SUCCEEDED:
      return state.set("classifications", action.classifications)

    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_FETCH_SUCCEEDED:
      return state.merge(action.productClassification)

    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_SELECTED:
      return state.merge({
        isDirty: true,
        classificationId: action.classificationId,
      })

    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_UPDATE_SUCCEEDED:
      return state.merge(action.productClassification).set("isDirty", false)

    default:
      return state
  }
}

export default productClassificationsReducer
