import * as AdminClient from "highline/api/admin/admin_client"

export const fetch = async (authToken, userId) => {
  return AdminClient.get(`admin/users/${ userId }`, {}, {
    "X-Authentication-Token": authToken,
  })
}

export const getAuthCode = async (authToken, googleAuthResponse) => {
  return AdminClient.getWithAuth("google_oauth2/callback", {
    code: googleAuthResponse.get("code"),
    redirect_uri: "postmessage",
  }, {
    "X-Authentication-Token": authToken,
  })
}
