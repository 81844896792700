import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  authenticationToken: null,
  authorized: false,
})

const authReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_AUTHENTICATED:
      return state.set("authorized", true)

    case AdminActionTypes.ADMIN_LOADED_WITH_AUTH_TOKEN:
    case AdminActionTypes.ADMIN_PAGE_LOADED:
      return state.merge({
        authenticationToken: action.authenticationToken,
      })

    default:
      return state
  }
}

export default authReducer
