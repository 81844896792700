/* eslint-disable sort-keys */
import keyMirror from "fbjs/lib/keyMirror"

export default keyMirror({
  ADMIN_AUTH_FAILED: null,
  ADMIN_AUTHENTICATED: null,
  ADMIN_CATEGORIES_ADD_CHILD_CLICKED: null,
  ADMIN_CATEGORIES_CREATE_CATEGORY_INPUT_CHANGED: null,
  ADMIN_CATEGORIES_CREATE_FAILED: null,
  ADMIN_CATEGORIES_CREATE_SUCCEEDED: null,
  ADMIN_CATEGORIES_DELETE_FAILED: null,
  ADMIN_CATEGORIES_DELETE_SUCCEEDED: null,
  ADMIN_CATEGORIES_EDIT_CATEGORY_INPUT_CHANGED: null,
  ADMIN_CATEGORIES_FETCH_ALL_FAILED: null,
  ADMIN_CATEGORIES_FETCH_ALL_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_ADD_PROGRAM_FAILED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_ADD_PROGRAM_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_COLOR_MANAGEMENT_CLOSE_CLICKED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_COLOR_MANAGEMENT_SEARCH_INPUT_CHANGED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_COLOR_MANAGEMENT_SEARCH_SELECTED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_CREATE_CATEGORY_PROGRAM_RULE_TYPE_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_CREATE_CATEGORY_PROGRAM_RULE_TYPE_FAILED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_CATEGORY_PROGRAM_RULE_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_CATEGORY_PROGRAM_RULE_FAILED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_CREATE_CATEGORY_PROGRAM_RULE_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_CREATE_CATEGORY_PROGRAM_RULE_FAILED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_PROGRAM_FAILED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_PROGRAM_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_CATEGORY_PROGRAM_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_CATEGORY_PROGRAM_FAILED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_COMPLETED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_FAILED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_STARTED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_FETCH_SEARCH_RESULTS_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_FILTERS_UPDATE_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_FILTER_TYPE_CHANGED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_LOADED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_LOAD_COMPLETED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_LOAD_FAILED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_LOAD_SUCCEEDED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_MANAGE_PROGRAM_CLICKED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_SEARCH_INPUT_CHANGED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_UPDATE_PROGRAM_FAILED: null,
  ADMIN_CATEGORIES_MANAGE_PROGRAMS_UPDATE_PROGRAM_SUCCEEDED: null,
  ADMIN_CATEGORIES_MODAL_CLOSE_CLICKED: null,
  ADMIN_CATEGORIES_TREE_DATA_CHANGED: null,
  ADMIN_CATEGORIES_VISIBILITY_TOGGLED: null,
  ADMIN_CATEGORY_FETCH_FAILED: null,
  ADMIN_CATEGORY_FETCH_STARTED: null,
  ADMIN_CATEGORY_FETCH_SUCCEEDED: null,
  ADMIN_CATEGORY_REQUEST_COMPLETED: null,
  ADMIN_CATEGORY_REQUEST_STARTED: null,
  ADMIN_CATEGORY_UPDATE_FAILED: null,
  ADMIN_CATEGORY_UPDATE_SUCCEEDED: null,
  ADMIN_CLASSIFICATIONS_DELETE_CLICKED: null,
  ADMIN_CLASSIFICATIONS_DELETE_FAILED: null,
  ADMIN_CLASSIFICATIONS_DELETE_MODAL_CLOSED: null,
  ADMIN_CLASSIFICATIONS_DELETE_STARTED: null,
  ADMIN_CLASSIFICATIONS_DELETE_SUCCEEDED: null,
  ADMIN_CLASSIFICATIONS_EDIT_CANCELED: null,
  ADMIN_CLASSIFICATIONS_EDIT_DELETE_MODAL_TOGGLED: null,
  ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_ASSOCIATION_ADDED: null,
  ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_ASSOCIATION_DELETED: null,
  ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_ASSOCIATION_INPUT_CHANGED: null,
  ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_CLOSE_CLICKED: null,
  ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_FAILED: null,
  ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_STARTED: null,
  ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_SUCCEEDED: null,
  ADMIN_CLASSIFICATIONS_EDIT_INPUT_CHANGED: null,
  ADMIN_CLASSIFICATIONS_EDIT_NEW_CLASSIFICATION_STARTED: null,
  ADMIN_CLASSIFICATIONS_EDIT_SIZE_CHART_PREVIEW_CLOSE_CLICKED: null,
  ADMIN_CLASSIFICATIONS_EDIT_SIZE_CHART_PREVIEW_FAILED: null,
  ADMIN_CLASSIFICATIONS_EDIT_SIZE_CHART_PREVIEW_STARTED: null,
  ADMIN_CLASSIFICATIONS_EDIT_SIZE_CHART_PREVIEW_SUCCEEDED: null,
  ADMIN_CLASSIFICATIONS_FETCH_ALL_FAILED: null,
  ADMIN_CLASSIFICATIONS_FETCH_ALL_STARTED: null,
  ADMIN_CLASSIFICATIONS_FETCH_ALL_SUCCEEDED: null,
  ADMIN_CLASSIFICATIONS_FETCH_FAILED: null,
  ADMIN_CLASSIFICATIONS_FETCH_STARTED: null,
  ADMIN_CLASSIFICATIONS_FETCH_SUCCEEDED: null,
  ADMIN_CLASSIFICATIONS_LOAD_FORM_OPTIONS_STARTED: null,
  ADMIN_CLASSIFICATIONS_LOAD_FORM_OPTIONS_SUCCEEDED: null,
  ADMIN_CLASSIFICATIONS_NEW_CLICKED: null,
  ADMIN_CLASSIFICATIONS_UPDATE_FAILED: null,
  ADMIN_CLASSIFICATIONS_UPDATE_STARTED: null,
  ADMIN_CLASSIFICATIONS_UPDATE_SUCCEEDED: null,
  ADMIN_EDUCATION_GROUPS_DELETE_FAILED: null,
  ADMIN_EDUCATION_GROUPS_DELETE_MODAL_CLOSED: null,
  ADMIN_EDUCATION_GROUPS_DELETE_MODAL_OPENED: null,
  ADMIN_EDUCATION_GROUPS_DELETE_STARTED: null,
  ADMIN_EDUCATION_GROUPS_DELETE_SUCCEEDED: null,
  ADMIN_EDUCATION_GROUPS_EDIT_CANCELED: null,
  ADMIN_EDUCATION_GROUPS_EDIT_DELETE_MODAL_TOGGLED: null,
  ADMIN_EDUCATION_GROUPS_EDIT_FULLSCREEN_TOGGLED: null,
  ADMIN_EDUCATION_GROUPS_EDIT_INPUT_CHANGED: null,
  ADMIN_EDUCATION_GROUPS_EDIT_ITEM_ADDED: null,
  ADMIN_EDUCATION_GROUPS_EDIT_ITEM_DELETED: null,
  ADMIN_EDUCATION_GROUPS_EDIT_ITEM_INPUT_CHANGED: null,
  ADMIN_EDUCATION_GROUPS_EDIT_LEAVE: null,
  ADMIN_EDUCATION_GROUPS_EDIT_POSITION_CHANGED: null,
  ADMIN_EDUCATION_GROUPS_FETCH_ALL_FAILED: null,
  ADMIN_EDUCATION_GROUPS_FETCH_ALL_STARTED: null,
  ADMIN_EDUCATION_GROUPS_FETCH_ALL_SUCCEEDED: null,
  ADMIN_EDUCATION_GROUPS_FETCH_FAILED: null,
  ADMIN_EDUCATION_GROUPS_FETCH_STARTED: null,
  ADMIN_EDUCATION_GROUPS_FETCH_SUCCEEDED: null,
  ADMIN_EDUCATION_GROUPS_NEW_CLICKED: null,
  ADMIN_EDUCATION_GROUPS_UPDATE_FAILED: null,
  ADMIN_EDUCATION_GROUPS_UPDATE_STARTED: null,
  ADMIN_EDUCATION_GROUPS_UPDATE_SUCCEEDED: null,
  ADMIN_FIT_PREFERENCES_EDIT_TOGGLED: null,
  ADMIN_FIT_PREFERENCES_OPTION_TYPES_FETCH_FAILED: null,
  ADMIN_FIT_PREFERENCES_OPTION_TYPES_FETCH_STARTED: null,
  ADMIN_FIT_PREFERENCES_OPTION_TYPES_FETCH_SUCCEEDED: null,
  ADMIN_FIT_PREFERENCES_USER_SELECTIONS_FETCH_FAILED: null,
  ADMIN_FIT_PREFERENCES_USER_SELECTIONS_FETCH_STARTED: null,
  ADMIN_FIT_PREFERENCES_USER_SELECTIONS_FETCH_SUCCEEDED: null,
  ADMIN_FIT_PREFERENCES_USER_SELECTIONS_UPDATE_FAILED: null,
  ADMIN_FIT_PREFERENCES_USER_SELECTIONS_UPDATE_STARTED: null,
  ADMIN_FIT_PREFERENCES_USER_SELECTIONS_UPDATE_SUCCEEDED: null,
  ADMIN_FLASH_DISMISSED: null,
  ADMIN_HIDE_HEADER: null,
  ADMIN_LOADED_WITH_AUTH_TOKEN: null,
  ADMIN_NAVIGATION_ITEM_DELETE_FAILED: null,
  ADMIN_NAVIGATION_ITEM_DELETE_SUCCEEDED: null,
  ADMIN_NAVIGATION_ITEM_FETCH_FAILED: null,
  ADMIN_NAVIGATION_ITEM_FETCH_SUCCEEDED: null,
  ADMIN_NAVIGATION_ITEM_POSITION_UPDATE_SUCCEEDED: null,
  ADMIN_NAVIGATION_ITEM_UPDATE_FAILED: null,
  ADMIN_NAVIGATION_ITEM_UPDATE_SUCCEEDED: null,
  ADMIN_NAVIGATION_ITEMS_ADD_CHILD_CLICKED: null,
  ADMIN_NAVIGATION_ITEMS_CREATE_FAILED: null,
  ADMIN_NAVIGATION_ITEMS_CREATE_NAVIGATION_ITEM_INPUT_CHANGED: null,
  ADMIN_NAVIGATION_ITEMS_CREATE_SUCCEEDED: null,
  ADMIN_NAVIGATION_ITEMS_EDIT_NAVIGATION_ITEM_INPUT_CHANGED: null,
  ADMIN_NAVIGATION_ITEMS_FETCH_ALL_FAILED: null,
  ADMIN_NAVIGATION_ITEMS_FETCH_ALL_SUCCEEDED: null,
  ADMIN_NAVIGATION_ITEMS_MODAL_CLOSE_CLICKED: null,
  ADMIN_NAVIGATION_ITEMS_REQUEST_COMPLETED: null,
  ADMIN_NAVIGATION_ITEMS_REQUEST_STARTED: null,
  ADMIN_NAVIGATION_ITEMS_TREE_DATA_CHANGED: null,
  ADMIN_NAVIGATION_ITEMS_VISIBILITY_TOGGLED: null,
  ADMIN_PAGE_LOADED: null,
  ADMIN_PRODUCTS_FETCH_COMPLETED: null,
  ADMIN_PRODUCTS_FETCH_STARTED: null,
  ADMIN_PRODUCTS_PRODUCT_FETCH_FAILED: null,
  ADMIN_PRODUCTS_PRODUCT_FETCH_SUCCEEDED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_DELETE_MODAL_TOGGLED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_DELETE_STARTED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_DELETE_SUCCEEDED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_FETCH_FAILED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_FETCH_STARTED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_FETCH_SUCCEEDED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_SELECTED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_UPDATE_FAILED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_UPDATE_STARTED: null,
  ADMIN_PRODUCT_CLASSIFICATIONS_UPDATE_SUCCEEDED: null,
  ADMIN_PRODUCT_SEARCH_FETCH_FAILED: null,
  ADMIN_PRODUCT_SEARCH_FETCH_SUCCEEDED: null,
  ADMIN_PRODUCT_SEARCH_INPUT_CHANGED: null,
  ADMIN_PRODUCT_SEARCH_LOADED: null,
  ADMIN_PRODUCTS_UPDATE_COLOR_POSITION_FAILED: null,
  ADMIN_PRODUCTS_UPDATE_COLOR_POSITION_SUCCEEDED: null,
  ADMIN_RESET_VARIANT_GROUPS_REQUEST_STARTED: null,
  ADMIN_RESET_VARIANT_GROUPS_REQUEST_COMPLETED: null,
  ADMIN_RESET_VARIANT_GROUPS_FAILED: null,
  ADMIN_RESET_VARIANT_GROUPS_SUCCEEDED: null,
  ADMIN_ROUTE_CHANGED: null,
  ADMIN_ROUTE_CHANGE_STARTED: null,
  ADMIN_SIZE_CHARTS_CREATE_FAILED: null,
  ADMIN_SIZE_CHARTS_CREATE_STARTED: null,
  ADMIN_SIZE_CHARTS_CREATE_SUCCEEDED: null,
  ADMIN_SIZE_CHARTS_DELETE_FAILED: null,
  ADMIN_SIZE_CHARTS_DELETE_STARTED: null,
  ADMIN_SIZE_CHARTS_DELETE_SUCCEEDED: null,
  ADMIN_SIZE_CHARTS_FETCH_ALL_FAILED: null,
  ADMIN_SIZE_CHARTS_FETCH_ALL_STARTED: null,
  ADMIN_SIZE_CHARTS_FETCH_ALL_SUCCEEDED: null,
  ADMIN_SIZE_CHARTS_FETCH_FAILED: null,
  ADMIN_SIZE_CHARTS_FETCH_STARTED: null,
  ADMIN_SIZE_CHARTS_FETCH_SUCCEEDED: null,
  ADMIN_SIZE_CHARTS_FILE_UPLOADED: null,
  ADMIN_SIZE_CHARTS_MODAL_CLOSED: null,
  ADMIN_SIZE_CHARTS_MODAL_OPENED: null,
  ADMIN_SIZE_CHARTS_PREVIEW_FAILED: null,
  ADMIN_SIZE_CHARTS_PREVIEW_STARTED: null,
  ADMIN_SIZE_CHARTS_PREVIEW_SUCCEEDED: null,
  ADMIN_USERS_FETCH_STARTED: null,
  ADMIN_USERS_FETCH_SUCCEEDED: null,
  ADMIN_USERS_FETCH_FAILED: null,
  ADMIN_USERS_FETCH_COMPLETED: null,
  ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_FAILED: null,
  ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_STARTED: null,
  ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_SUCCEEDED: null,
  ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_COMPLETED: null,
  ADMIN_WALLET_PAYMENT_METHODS_DELETE_CLICKED: null,
  ADMIN_WALLET_PAYMENT_METHODS_DELETE_MODAL_CLOSED: null,
  ADMIN_WALLET_PAYMENT_METHODS_DELETE_STARTED: null,
  ADMIN_WALLET_PAYMENT_METHODS_DELETE_SUCCEEDED: null,
  ADMIN_WALLET_PAYMENT_METHODS_DELETE_FAILED: null,
  ADMIN_WALLET_PAYMENT_METHODS_DELETE_COMPLETED: null,
  ADMIN_WALLET_PAYMENT_METHODS_NEW_CLICKED: null,
  ADMIN_WALLET_PAYMENT_METHODS_NEW_MODAL_CLOSED: null,
  ADMIN_WALLET_PAYMENT_METHODS_NEW_STARTED: null,
  ADMIN_WALLET_PAYMENT_METHODS_NEW_FAILED: null,
  ADMIN_WALLET_PAYMENT_METHODS_NEW_SUCCEEDED: null,
  ADMIN_WALLET_PAYMENT_METHODS_NEW_COMPLETED: null,
  ADMIN_USER_LOGGED_IN: null,
  ADMIN_REDIRECT_FOUND: null,
  ADMIN_CURRENT_PAGE_CHANGED: null,
  ADMIN_USER_STOCK_LOCATIONS_PAGE_LOADED: null,
  ADMIN_USER_STOCK_LOCATIONS_REQUEST_SUCCEEDED: null,
  ADMIN_USER_STOCK_LOCATIONS_REQUEST_FAILED: null,
  ADMIN_FILE_UPLOAD: null,
  ADMIN_FILE_UPLOAD_REQUEST_SUCCEEDED: null,
  ADMIN_FILE_UPLOAD_REQUEST_FAILED: null,
  CLEAR_FILE_UPLOAD_RESPONSE: null,
  ADMIN_USER_LOGIN_FAILED: null,
})
