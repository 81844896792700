import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  details: {},
})

const showReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_USERS_FETCH_SUCCEEDED:
      return state.set("details", action.userDetails)

    default:
      return state
  }
}

export default showReducer
