import { combineReducers } from "redux-immutable"
import indexReducer from "highline/redux/admin/reducers/navigation_items/index_reducer"
import editReducer from "highline/redux/admin/reducers/navigation_items/edit_reducer"

const rootReducer = combineReducers({
  edit: editReducer,
  index: indexReducer,
})

export default rootReducer
