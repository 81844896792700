import React from "react"
import ReactCSSTransitionGroup from "react-transition-group/CSSTransitionGroup"
import PropTypes from "prop-types"
import classNames from "classnames"
import FlashNotification from "highline/components/flash_notification"
import styles from "styles/components/admin/flash.module.css"

const DURATIONS = {
  success: 3000,
  error: 10000,
}

const Flash = ({
  level,
  message,
  onDismiss,
  show,
}) => (
  <ReactCSSTransitionGroup
    transitionName={ {
      enter: styles.enter,
      enterActive: styles.enterActive,
      leave: styles.leave,
      leaveActive: styles.leaveActive,
    } }
    transitionEnterTimeout={ 300 }
    transitionLeaveTimeout={ 300 }
  >
    { show &&
      <div className={ classNames(
        "component",
        "flash-component",
        styles.component,
      ) }>
        <FlashNotification
          key="flash"
          duration={ DURATIONS[level] }
          level={ level }
          message={ message }
          onDismiss={ onDismiss }
        />
      </div>
    }
  </ReactCSSTransitionGroup>
)

Flash.propTypes = {
  level: PropTypes.string,
  message: PropTypes.string,
  onDismiss: PropTypes.func,
  show: PropTypes.bool,
}

export default Flash
