export const restoreExpanded = (treeData, expandedCategoriesSet) => {
  if (treeData.length == 0 || expandedCategoriesSet.size == 0)
    return treeData

  const queue = [...treeData]

  // Traverse tree (breadth-first search), updating expanded on each node
  while (queue.length > 0) {
    const current = queue.shift()
    current.expanded = expandedCategoriesSet.has(current.id)

    if (current.children && current.children.length > 0) {
      queue.push(...current.children)
    }
  }

  return treeData
}
