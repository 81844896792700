import { fromJS } from "immutable"
import { createStore, applyMiddleware } from "redux"
import { combineReducers } from "redux-immutable"
import { isClient } from "highline/utils/client"
import thunkMiddleware from "redux-thunk"
import adminAnalyticsMiddleware from "highline/redux/admin/middlewares/analytics_middleware"
import adminLocalStorageMiddleware from "highline/redux/admin/middlewares/local_storage_middleware"
import navigationMiddleware from "highline/redux/admin/middlewares/navigation_middleware"
import adminAuthReducer from "highline/redux/admin/reducers/auth_reducer"
import adminCategoriesReducer from "highline/redux/admin/reducers/categories_reducer"
import adminClassificationsReducer from "highline/redux/admin/reducers/classifications_reducer"
import adminEducationGroupsReducer from "highline/redux/admin/reducers/education_groups_reducer"
import adminFlashReducer from "highline/redux/admin/reducers/flash_reducer"
import adminLoadingReducer from "highline/redux/admin/reducers/loading_reducer"
import adminNavigationItemsReducer from "highline/redux/admin/reducers/navigation_items_reducer"
import adminNavReducer from "highline/redux/admin/reducers/nav_reducer"
import adminProductClassificationsReducer from "highline/redux/admin/reducers/product_classifications_reducer"
import adminProductSearchReducer from "highline/redux/admin/reducers/product_search_reducer"
import adminProductsReducer from "highline/redux/admin/reducers/products_reducer"
import adminResetVariantGroupsReducer from "highline/redux/admin/reducers/reset_variant_groups_reducer"
import adminSizeChartsReducer from "highline/redux/admin/reducers/size_charts_reducer"
import adminUsersReducer from "highline/redux/admin/reducers/users_reducer"
import ccpafileUploadReducer from "highline/redux/admin/reducers/ccpa_file_upload_reducer"
import adminUserStockLocationsReducer from "highline/redux/admin/reducers/user_stock_locations_reducer"
import adminUiReducer from "highline/redux/admin/reducers/ui_reducer"
import adminWalletReducer from "highline/redux/admin/reducers/wallet_reducer"
import adminFitPreferencesReducer from "highline/redux/admin/reducers/fit_preferences_reducer"
import billingInformationReducer from "highline/redux/reducers/billing_information_reducer"
import locationReducer from "highline/redux/reducers/location_reducer"
import currentPageReducer from "highline/redux/admin/reducers/current_page_reducer"
import breadcrumbsReducer from "highline/redux/admin/reducers/breadcrumbs_reducer"
import AdminActionTypes from "highline/redux/admin/action_types"

const rootReducer = combineReducers({
  auth: adminAuthReducer,
  billingInformation: billingInformationReducer,
  breadcrumbs: breadcrumbsReducer,
  categories: adminCategoriesReducer,
  classifications: adminClassificationsReducer,
  currentPage: currentPageReducer,
  educationGroups: adminEducationGroupsReducer,
  fitPreferences: adminFitPreferencesReducer,
  flash: adminFlashReducer,
  loading: adminLoadingReducer,
  location: locationReducer,
  nav: adminNavReducer,
  navigationItems: adminNavigationItemsReducer,
  productClassifications: adminProductClassificationsReducer,
  products: adminProductsReducer,
  productSearch: adminProductSearchReducer,
  resetVariantGroups: adminResetVariantGroupsReducer,
  sizeCharts: adminSizeChartsReducer,
  ui: adminUiReducer,
  users: adminUsersReducer,
  userStockLocations: adminUserStockLocationsReducer,
  wallet: adminWalletReducer,
  fileUpload:ccpafileUploadReducer,
})

let middleware = [
  navigationMiddleware,
  thunkMiddleware, /* needs to be first */
  adminAnalyticsMiddleware,
  adminLocalStorageMiddleware,
]

if (process.env.NODE_ENV !== "production" && isClient) {
  const createLogger = require("redux-logger").createLogger
  const logger = createLogger({
    stateTransformer: (state) => state && state.toJS(), // readable immutable
  })

  middleware = [...middleware, logger]
}

export default (initialState, pageParams)  => {
  initialState = setupPage(initialState, pageParams)
  return createStore(
    rootReducer,
    fromJS(initialState),
    applyMiddleware(...middleware),
  )
}

function setupPage(initialState = {}, pageParams) {
  const action = { type: AdminActionTypes.ADMIN_CURRENT_PAGE_CHANGED, ...pageParams }
  const currentPage = currentPageReducer(undefined, action)

  return Object.assign(initialState, { currentPage })
}

