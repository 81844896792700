import AdminActionTypes from "highline/redux/admin/action_types"
import { trackEvent } from "highline/utils/segment/segment_helper"

const adminAnalyticsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {

    // Admin search events
    case AdminActionTypes.ADMIN_PRODUCT_SEARCH_FETCH_SUCCEEDED: {
      trackEvent("Admin Search Completed", {
        num_results: action.data.get("products").size,
        term: store.getState().getIn(["productSearch", "term"]),
      })
      break
    }
  }

  return next(action)
}

export default adminAnalyticsMiddleware
