import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"
import { formatSizeChartData } from "highline/redux/helpers/size_charts_helper"

const initialState = fromJS({
  deleteModal: { id: null, isOpen: false, name: null },
  description: "",
  errors: {},
  heading: "",
  id: "",
  name: "",
  previewModal: { isOpen: false, name: null, sizeCharts: null },
  previewSizeCharts: { description: "", heading: "",  name: "", sizeCharts: [] },
  sizeChartFile: null,
  sizeCharts: [],
  sizeChartsLoaded: false,
})

const editReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_SIZE_CHARTS_FILE_UPLOADED:
      return state.set("sizeChartFile", action.file)

    case AdminActionTypes.ADMIN_SIZE_CHARTS_MODAL_CLOSED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_DELETE_SUCCEEDED:
      return state.set(action.modalName, initialState.get(action.modalName))

    case AdminActionTypes.ADMIN_SIZE_CHARTS_MODAL_OPENED:
      return state.set(action.modalName, fromJS({
        id: action.id,
        isOpen: true,
        name: action.name,
      }))

    case AdminActionTypes.ADMIN_SIZE_CHARTS_FETCH_FAILED:
      return state.merge({
        errors: state.get("errors").merge({ index: "Something went wrong" }),
      })

    case AdminActionTypes.ADMIN_SIZE_CHARTS_PREVIEW_SUCCEEDED:
      return state.merge({
        previewModal: fromJS({ isOpen: true }),
        previewSizeCharts: fromJS({
          description: action.sizeCharts.get("description"),
          heading: action.sizeCharts.get("heading"),
          name: action.sizeCharts.get("name"),
          sizeCharts: formatSizeChartData(action.sizeCharts),
        }),
      })

    case AdminActionTypes.ADMIN_SIZE_CHARTS_FETCH_STARTED:
      return initialState

    case AdminActionTypes.ADMIN_SIZE_CHARTS_FETCH_SUCCEEDED:
      return state.merge({
        description: action.sizeChart.get("description"),
        heading: action.sizeChart.get("heading"),
        id: action.sizeChart.get("id"),
        name: action.sizeChart.get("name"),
        sizeCharts: formatSizeChartData(action.sizeChart),
        sizeChartsLoaded: true,
      })

    case AdminActionTypes.ADMIN_SIZE_CHARTS_CREATE_FAILED:
      return state.set("previewModal", fromJS({
        isOpen: false,
      }))

    case AdminActionTypes.ADMIN_SIZE_CHARTS_DELETE_FAILED:
      return state.set("deleteModal", fromJS({
        isOpen: false,
      }))

    default:
      return state
  }
}

export default editReducer
