import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { CloseIcon } from "highline/components/icons"
import styles from "styles/components/flash_notification.module.css"

class FlashNotification extends React.PureComponent {
  static propTypes = {
    duration: PropTypes.number,
    level: PropTypes.oneOf(["error", "success"]).isRequired,
    message: PropTypes.string.isRequired,
    onDismiss: PropTypes.func,
  }

  static defaultProps = {
    duration: 0,
    onDismiss: () => {},
  }

  componentDidMount() {
    if (this.props.duration) {
      this.timeout = setTimeout(
        () => this.props.onDismiss(),
        this.props.duration,
      )
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const {
      level,
      message,
      onDismiss,
    } = this.props

    return (
      <div className={ classNames(
        "component",
        "flash-notification-component",
        styles.component,
        styles[level],
      ) }>
        <div className={ styles.message }>
          { message }
        </div>

        <button className={ styles.close } onClick={ onDismiss }>
          <div className={ styles.icon }>
            <CloseIcon />
          </div>
        </button>
      </div>
    )
  }
}

export default FlashNotification
