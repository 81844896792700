import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  educationItems: [],
  errors: {},
  id: null,
  isDirty: false,
  isFullscreen: false,
  lastSaved: {},
  markdown: "",
  name: "",
  showDeleteModal: false,
})

const editReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_EDIT_ITEM_ADDED:
      return state.merge({
        educationItems: state.get("educationItems").push(newEducationItem()),
        isDirty: true,
      })

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_EDIT_DELETE_MODAL_TOGGLED:
      return state.set("showDeleteModal", !state.get("showDeleteModal"))

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_EDIT_FULLSCREEN_TOGGLED:
      return state.set("isFullscreen", !state.get("isFullscreen"))

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_EDIT_ITEM_DELETED:
      return state.deleteIn(["educationItems", action.index]).set("isDirty", true)

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_EDIT_CANCELED:
      return initialState.merge(state.get("lastSaved")).set("lastSaved", state.get("lastSaved"))

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_EDIT_POSITION_CHANGED:
      return swap(state, action).set("isDirty", true)

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_EDIT_INPUT_CHANGED:
      return state.set(action.name, action.value).set("isDirty", true)

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_EDIT_LEAVE:
      return initialState

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_EDIT_ITEM_INPUT_CHANGED:
      return updateItem(state, action).set("isDirty", true)

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_UPDATE_FAILED:
      return state.set("errors", action.errors)

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_STARTED:
      return initialState

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_UPDATE_SUCCEEDED:
      return state.merge(action.educationGroup).merge(fromJS({
        isDirty: false,
        lastSaved: action.educationGroup,
        errors: {},
      }))

    default:
      return state
  }
}

function newEducationItem() {
  return fromJS({
    imageId: null,
    imageUrl: "",
    markdown: "",
    title: "",
  })
}

function updateItem(state, action) {
  const item = state.getIn(["educationItems", action.position])
  const updatedItem = item.set(action.name, action.value)
  return state.setIn(["educationItems", action.position], updatedItem)
}

function swap(state, action) {
  const swapPosition = action.direction === "up"
    ? action.index - 1
    : action.index + 1

  const item = state.getIn(["educationItems", action.index])
  const other = state.getIn(["educationItems", swapPosition])

  return state
    .setIn(["educationItems", swapPosition], item)
    .setIn(["educationItems", action.index], other)
}

export default editReducer
