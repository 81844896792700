import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  isVisible: false,
  level: "",
  message: "",
})

const flashReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_FLASH_DISMISSED:
      return initialState

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_DELETE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Classification Deleted",
      })

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_UPDATE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Classification Saved",
      })

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_UPDATE_FAILED:
      return initialState.merge({
        isVisible: true,
        level: "error",
        message: "Classification did not save",
      })

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_DELETE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Education Group Deleted",
      })

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_UPDATE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Education Group Updated",
      })


    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_DELETE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Product Classifiaction was removed",
      })

    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_UPDATE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Product Classification Updated",
      })


    case AdminActionTypes.ADMIN_SIZE_CHARTS_CREATE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Size Chart Created",
      })

    case AdminActionTypes.ADMIN_SIZE_CHARTS_DELETE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Size Chart Deleted",
      })

    case AdminActionTypes.ADMIN_CATEGORY_UPDATE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Category updated",
      })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_ADD_PROGRAM_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Program added",
      })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_UPDATE_PROGRAM_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Program updated",
      })

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_DELETE_PROGRAM_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Program deleted",
      })

    case AdminActionTypes.ADMIN_CATEGORY_UPDATE_FAILED:
      return initialState.merge({
        isVisible: true,
        level: "error",
        message: "Category did not save",
      })

    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_CREATE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Navigation Item Created",
      })

    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_POSITION_UPDATE_SUCCEEDED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_UPDATE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Navigation Item Updated",
      })

    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_DELETE_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "Navigation Item Deleted",
      })

    case AdminActionTypes.ADMIN_USER_STOCK_LOCATIONS_REQUEST_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "File uploaded successfully",
      })
    case AdminActionTypes.ADMIN_FILE_UPLOAD_REQUEST_SUCCEEDED:
      return initialState.merge({
        isVisible: true,
        level: "success",
        message: "File uploaded successfully",
      })
    case AdminActionTypes.ADMIN_FILE_UPLOAD_REQUEST_FAILED:
      return initialState.merge({
        isVisible: true,
        level: "error",
        message: action.error,
      })
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_FAILED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_ALL_FAILED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_UPDATE_FAILED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_CREATE_FAILED:
    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_UPDATE_FAILED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_DELETE_FAILED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_FETCH_ALL_FAILED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_PREVIEW_FAILED:
    case AdminActionTypes.ADMIN_AUTH_FAILED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_FETCH_FAILED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_FETCH_ALL_FAILED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_CREATE_FAILED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_DELETE_FAILED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_UPDATE_FAILED:
    case AdminActionTypes.ADMIN_USER_STOCK_LOCATIONS_REQUEST_FAILED:
      return initialState.merge(getDefaultError(action))
    default:
      return state
  }
}

function getDefaultError(action) {
  const fatalMessage = action.errors && action.errors.get("fatal")

  const message = fatalMessage
    ? `Something went wrong: ${fatalMessage}`
    : "Something went wrong"

  return {
    isVisible: true,
    level: "error",
    message,
  }
}

export default flashReducer
