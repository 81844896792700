import axios from "axios"
import Qs from "qs"
import { isServer } from "highline/utils/client"
import { toCamelizedImmutable } from "highline/utils/immutable_helper"
import { remove as clearAuthToken } from "highline/storage/user_auth_storage"
import { redirect } from "highline/utils/navigate"

import {
  authURL,
  baseURL,
  adminClientAuthToken,
  logHttpError,
} from "highline/api/base"

// All admin calls should be performed client-side
// (need to be logged in with admin credentials)
async function performAdminRequest(client, options) {
  if (isServer)
    throw new Error("ServerSideAdminApiRequest: admin api calls not allowed from server")

  try {
    const response = await client.request(options)
    response.data = toCamelizedImmutable(response.data)
    return response

  } catch (error) {
    logHttpError("Admin", error)

    if (error.response) {
      // for any unauthorized request,
      // logout user and redirect to sign-in
      if (isUnauthorized(error.response)) {
        clearAuthToken()
        redirect("/admin-new")
      }

      const response = error.response
      response.data = toCamelizedImmutable(response.data)
      throw response // surface failed api call

    } else {
      throw error
    }
  }
}

function isUnauthorized(response) {
  if (response.status == 401)
    return true

  if (response.data && response.data.errors) {
    if (response.data.errors.fatal_error_key == "invalid_user_token")
      return true
  }

  return false
}

export const adminApiClient = axios.create({
  baseURL,
  headers: {
    "Accept": "application/json;v=2.0;schema=admin",
    "X-Client-Authentication-Token": adminClientAuthToken,
  },
  paramsSerializer: (params) => {
    return Qs.stringify(params, { arrayFormat: "brackets" })
  },
  validateStatus: (status) => {
    return status < 400 // only error for 400 and up
  },
})

export const adminAuthClient = axios.create({
  baseURL: authURL,
  headers: {},
  paramsSerializer: (params) => {
    return Qs.stringify(params, { arrayFormat: "brackets" })
  },
  validateStatus: (status) => {
    return status < 400 // only error for 400 and up
  },
})

export const get = function(url, params = {}, headers = {}) {
  return performAdminRequest(adminApiClient, {
    method: "get",
    url,
    params,
    headers,
  })
}

export const getWithAuth = function(url, params = {}, headers = {}) {
  return performAdminRequest(adminAuthClient, {
    method: "get",
    url,
    params,
    headers,
  })
}

export const put = function(url, params = {}, data = {}, headers = {}) {
  return performAdminRequest(adminApiClient, {
    method: "put",
    url,
    params,
    data,
    headers,
  })
}

export const del = function(url, params = {}, data = {}, headers = {}) {
  return performAdminRequest(adminApiClient, {
    method: "delete",
    url,
    params,
    data,
    headers,
  })
}

export const post = function(url, data = {}, headers = {}) {
  return performAdminRequest(adminApiClient, {
    method: "post",
    url,
    data,
    headers,
  })
}

// data should be a FormData object
// https://developer.mozilla.org/en-US/docs/Web/API/FormData
export const upload = function(url, data, headers = {}) {
  return performAdminRequest(adminApiClient, {
    method: "post",
    url,
    data,
    headers: Object.assign({}, { "content-type": "multipart/form-data" }, headers),
  })
}
