import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"
import { formatSizeChartData } from "highline/redux/helpers/size_charts_helper"

const initialState = fromJS({
  defaultOptionType: null,
  deleteModalIsOpen: false,
  educationGroupAssociations: [],
  educationGroupPreview: {
    educationGroup: {},
    isOpen: false,
  },
  errors: {},
  formOptions: {
    educationGroups: [],
    optionTypes: [],
    sizeCharts: [],
  },
  id: null,
  isDirty: false,
  lastSaved: {},
  name: "",
  sizeChartId: null,
  sizeChartPreview: {
    description: "",
    heading: "",
    isOpen: false,
    name: "",
    sizeCharts: [],
  },
})

const editReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_ASSOCIATION_ADDED:
      return state.merge({
        educationGroupAssociations: state.get("educationGroupAssociations").push(newEducationGroup()),
        isDirty: true,
      })

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_DELETE_MODAL_TOGGLED:
      return state.set("deleteModalIsOpen", !state.get("deleteModalIsOpen"))

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_ASSOCIATION_DELETED:
      return state.deleteIn(["educationGroupAssociations", action.index]).set("isDirty", true)

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_ASSOCIATION_INPUT_CHANGED:
      return updateAssociation(state, action).set("isDirty", true)

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_CLOSE_CLICKED:
      return state.setIn(["educationGroupPreview", "isOpen"], false)

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_SUCCEEDED:
      return state.set("educationGroupPreview", fromJS({
        educationGroup: action.educationGroup,
        isOpen: true,
      }))

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_INPUT_CHANGED:
      return state.set(action.name, action.value).set("isDirty", true)

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_CANCELED:
      return state
        .merge(state.get("lastSaved"))
        .merge({
          isDirty: false,
          lastSaved: state.get("lastSaved"),
        })

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_LOAD_FORM_OPTIONS_SUCCEEDED:
      return state.set("formOptions", action.formOptions)

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_NEW_CLASSIFICATION_STARTED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_STARTED:
      return initialState

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_UPDATE_FAILED:
      return state.set("errors", action.error)

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_UPDATE_SUCCEEDED:
      return state.merge(action.classification).merge(fromJS({
        errors: {},
        isDirty: false,
        lastSaved: action.classification,
      }))

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_SIZE_CHART_PREVIEW_CLOSE_CLICKED:
      return state.setIn(["sizeChartPreview", "isOpen"], false)

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_SIZE_CHART_PREVIEW_SUCCEEDED:
      return state.set("sizeChartPreview", fromJS({
        description: action.sizeCharts.get("description"),
        heading: action.sizeCharts.get("heading"),
        isOpen: true,
        name: action.sizeCharts.get("name"),
        sizeCharts: formatSizeChartData(action.sizeCharts),
      }))

    default:
      return state
  }
}

function newEducationGroup() {
  return fromJS({
    educationGroupId: null,
    optionTypeId: null,
    showSizeChart: false,
    title: "",
  })
}

function updateAssociation(state, action) {
  const association = state.getIn(["educationGroupAssociations", action.index])
  const updatedAssociation = association.set(action.name, action.value)
  return state.setIn(["educationGroupAssociations", action.index], updatedAssociation)
}

export default editReducer
