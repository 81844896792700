import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  deleteModal: {
    isOpen: false,
    id: null,
    name: null,
  },
  newModal: {
    isOpen: false,
    paymentMethodId: null,
    lastDigits: null,
  },
  errors: {},
  paymentMethodList: [],
})

const walletReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_SUCCEEDED:
      return state.set("paymentMethodList", action.paymentMethods)

    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_NEW_CLICKED:
      return state.set("newModal", fromJS({ isOpen: true }))

    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_NEW_SUCCEEDED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_NEW_MODAL_CLOSED:
      return state.set("newModal", initialState.get("newModal"))

    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_DELETE_CLICKED:
      return state.set("deleteModal", fromJS({
        isOpen: true,
        paymentMethodId: action.paymentMethodId,
        lastDigits: action.lastDigits,
      }))

    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_DELETE_SUCCEEDED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_DELETE_MODAL_CLOSED:
      return state.set("deleteModal", initialState.get("deleteModal"))

    case AdminActionTypes.ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_FAILED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_DELETE_FAILED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_NEW_FAILED:
      return state.set("errors", action.error)

    default:
      return state
  }
}

export default walletReducer
