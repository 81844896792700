import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  fileUploadResponse: {},
})

const ccpaFileUploadReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_FILE_UPLOAD_REQUEST_SUCCEEDED:
      return state.set("fileUploadResponse", { message: action.message,fileName:action.fileName })
    case AdminActionTypes.ADMIN_FILE_UPLOAD_REQUEST_FAILED:
      return state.set("fileUploadResponse", { type: "error", message: action.error,fileName:action.fileName })
    case AdminActionTypes.CLEAR_FILE_UPLOAD_RESPONSE:
      return state.set("fileUploadResponse", {})
    default:
      return state
  }
}

export default ccpaFileUploadReducer
