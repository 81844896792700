import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  colors: [],
  id: null,
  name: "",
})

export default function(state = initialState, action) {
  switch (action.type) {
    case AdminActionTypes.ADMIN_PRODUCTS_PRODUCT_FETCH_SUCCEEDED:
      return state.merge({
        colors: action.data.get("colors"),
        id: action.data.get("id"),
        name: action.data.get("name"),
      })

    case AdminActionTypes.ADMIN_PRODUCTS_UPDATE_COLOR_POSITION_SUCCEEDED:
      return state.set("colors", action.data.get("colors"))

    default:
      return state
  }
}
