import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  response: [],
})

const userStockLocationReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_USER_STOCK_LOCATIONS_REQUEST_SUCCEEDED:
      return state.set("response", action.response)

    default:
      return state
  }
}

export default userStockLocationReducer
