import { fromJS } from "immutable"

export const buildNonNestedBreadcrumbs = (baseUrl, isNew = false, id = null, name = null) => {
  const breadcrumbBase = fromJS([{
    as: baseUrl,
    href: baseUrl,
    name: "Index",
  }])

  if (id && name) {
    const href = `${ baseUrl }/[${ baseUrl.includes("product-classifications") ? "slug" : "id" }]`

    return breadcrumbBase.push(fromJS({
      as: `${ baseUrl }/${ id }`,
      href,
      name,
    }))
  }

  if (isNew) {
    return breadcrumbBase.push(fromJS({
      as: `${ baseUrl }/new`,
      href: `${ baseUrl }/new`,
      name: "New",
    }))
  }

  return breadcrumbBase
}

export const buildNavigationItemBreadcrumbs = (navigationItem) => {
  const breadcrumbBase = fromJS([{
    as: "/admin-new/navigation-items",
    href: "/admin-new/navigation-items",
    name: "Index",
  }])

  if (navigationItem) {
    const id = navigationItem.get("id")
    const label = navigationItem.get("label")
    const parentNavigationItems = navigationItem.get("parentNavigationItems")

    const allNavigationItems = parentNavigationItems.push(fromJS({
      id,
      label,
    }))

    return allNavigationItems.reduce(
      (breadcrumbs, navigationItem) => (
        breadcrumbs.push(fromJS({
          as: `/admin-new/navigation-items/${ navigationItem.get("id") }`,
          href: "/admin-new/navigation-items/[id]",
          name: navigationItem.get("label"),
        }))
      ),
      breadcrumbBase,
    )
  }
  return breadcrumbBase
}

export const buildCategoryManageProgramsBreadcrumbs = (category) => {
  return buildCategoryBreadcrumbs(category).push(fromJS({
    as: `/admin-new/categories/${category.get("id")}/manage-programs`,
    href: `/admin-new/categories/manage-programs?id=${category.get("id")}`,
    name: "Manage Programs",
  }))
}

export const buildCategoryBreadcrumbs = (category) => {
  const breadcrumbBase = fromJS([{
    as: "/admin-new/categories",
    href: "/admin-new/categories",
    name: "Index",
  }])

  if (category) {
    const id = category.get("id")
    const name = category.get("name")
    const parentCategories = category.get("parentCategories")

    const allCategories = parentCategories.push(fromJS({
      id,
      name,
    }))

    return allCategories.reduce(
      (breadcrumbs, category) => (
        breadcrumbs.push(fromJS({
          as: `/admin-new/categories/${ category.get("id") }`,
          href: "/admin-new/categories/[id]",
          name: category.get("name"),
        }))
      ),
      breadcrumbBase,
    )
  }

  return breadcrumbBase
}

export const buildProductsBreadcrumbs = (product = fromJS({})) => {
  const id = product.get("id")
  const name = product.get("name")
  return fromJS([{
    as: `/admin-new/products/${id}`,
    href: `/admin-new/products/${id}`,
    name,
  }])
}

export const getStaticBreadcrumbs = (href, name) => (fromJS([{
  as: href,
  href,
  name,
}]))
