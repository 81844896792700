import * as AdminClient from "highline/api/admin/admin_client"
import { toDecamelizedJSON } from "highline/utils/immutable_helper"

export const fetch = async (authToken, categoryId) => {
  return AdminClient.get(`admin/categories/${ categoryId }`, {}, {
    "X-Authentication-Token": authToken,
  })
}

export const fetchAll = (authToken) => {
  return AdminClient.get("/admin/categories", {}, {
    "X-Authentication-Token": authToken,
  })
}

export const fetchSearchedPrograms = (authToken, searchText) => {
  return AdminClient.get("/admin/programs", { name_search: searchText }, {
    "X-Authentication-Token": authToken,
  })
}

export const fetchCategoryProgram = (authToken, categoryId, categoryProgramId) => {
  return AdminClient.get(`/admin/categories/${categoryId}/category_programs/${categoryProgramId}`, {}, {
    "X-Authentication-Token": authToken,
  })
}

export const create = (authToken, name, parent_id, slug) => {
  return AdminClient.post("/admin/categories",
    {
      name,
      parent_id,
      slug,
    },
    { "X-Authentication-Token": authToken },
  )
}

export const update = (authenticationToken, category) => {
  const formattedCategoryData = toDecamelizedJSON(category)

  return AdminClient.put(`admin/categories/${ formattedCategoryData.id }`,
    {},
    formattedCategoryData,
    { "X-Authentication-Token": authenticationToken },
  )
}

export const destroy = (authToken, id) => {
  return AdminClient.del(`/admin/categories/${id}`,
    {},
    {},
    { "X-Authentication-Token": authToken },
  )
}

export const addProgram = (authToken, categoryId, program_id, program_type) => {
  return AdminClient.post(`/admin/categories/${ categoryId }/category_programs`,
    {
      program_id,
      program_type,
    },
    { "X-Authentication-Token": authToken },
  )
}

export const updateProgram = (authToken, categoryId, categoryProgramId, position) => {
  return AdminClient.put(`/admin/categories/${ categoryId }/category_programs/${ categoryProgramId }`,
    {},
    {
      position,
    },
    { "X-Authentication-Token": authToken },
  )
}

export const destroyProgram = (authToken, categoryId, categoryProgramId) => {
  return AdminClient.del(`/admin/categories/${ categoryId }/category_programs/${ categoryProgramId }`,
    {},
    {},
    { "X-Authentication-Token": authToken },
  )
}

export const createCategoryProgramRuleType = (authToken, categoryId, categoryProgramId, ruleType) => {
  return AdminClient.post(`/admin/categories/${ categoryId }/category_programs/${ categoryProgramId }/rule_types`,
    { rule_type: ruleType },
    { "X-Authentication-Token": authToken },
  )
}

export const createCategoryProgramRule = (authToken, categoryId, categoryProgramId, ruleType, optionValueId) => {
  return AdminClient.post(`/admin/categories/${ categoryId }/category_programs/${ categoryProgramId }/rules`,
    {
      option_value_id: optionValueId,
      rule_type: ruleType,
    },
    { "X-Authentication-Token": authToken },
  )
}

export const destroyCategoryProgramRule = (authToken, categoryId, categoryProgramId, ruleType, optionValueId) => {
  return AdminClient.del(`/admin/categories/${ categoryId }/category_programs/${ categoryProgramId }/rules`,
    {
      option_value_id: optionValueId,
      rule_type: ruleType,
    },
    {},
    { "X-Authentication-Token": authToken },
  )
}
