import Lockr from "lockr"

const STORAGE_KEY = "adminCategories"
const DEFAULTS = { expandedCategories: [] /* Set */ }

// Convert expandedCategories to Set when extracting
export const load = () => {
  const data = Lockr.get(STORAGE_KEY) || DEFAULTS
  const expandedCategories = new Set(data.expandedCategories)
  return { ...data, expandedCategories }
}

// Convert expandedCategories to Array when saving
export const save = (adminCategories) => {
  const { expandedCategories } = adminCategories

  const data = {
    ...adminCategories,
    expandedCategories: Array.from(expandedCategories),
  }

  Lockr.set(STORAGE_KEY, data)
}

export const expanded = (categoryId) => {
  const { expandedCategories } = load()
  expandedCategories.add(categoryId)
  save({ expandedCategories })
}

export const collapsed = (categoryId) => {
  const { expandedCategories } = load()
  expandedCategories.delete(categoryId)
  save({ expandedCategories })
}

export const clear = () => {
  Lockr.rm(STORAGE_KEY)
}
