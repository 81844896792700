import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import Router from "next/router"
import { connect } from "react-redux"
import withRedux from "highline/utils/with_redux"
import Breadcrumbs from "highline/components/breadcrumbs"
import Header from "highline/components/admin/header"
import Flash from "highline/components/admin/flash"
import LoadingCurtain from "highline/components/loading_curtain"
import getConfig from "highline/config/application"
import { registerInputEvents } from "highline/utils/input_events"
import initAnalytics from "highline/utils/segment/analytics"
import createAdminStore from "highline/redux/admin/store"
import AdminUserAuth from "highline/components/admin/admin_user_auth"
import {
  adminFlashDismissed,
  adminHideHeader,
  adminLoadedWithAuthToken,
  adminRouteChanged,
  adminRouteChangeStarted,
  checkLoggedInAsync,
} from "highline/redux/admin/actions/admin_layout_actions"
import styles from "highline/styles/layouts/with_admin_layout.module.css"

const mapStateToProps = (state, ownProps) => {
  return {
    activePage: state.getIn(["nav", "activePage"]),
    authorized: state.getIn(["auth", "authorized"]),
    breadcrumbs: state.getIn(["breadcrumbs", "breadcrumbs"]),
    flash: state.getIn(["flash"]),
    isLoading: state.getIn(["loading", "isLoading"]),
    pathname: ownProps.pathname,
    showLogo: state.getIn(["ui", "showLogo"]),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFlashDismiss: () => {
      dispatch(adminFlashDismissed())
    },
    onMount: () => {
      dispatch(checkLoggedInAsync())
    },
    onRouteChangeComplete: (pathname) => {
      dispatch(adminRouteChanged(pathname))
    },
    onRouteChangeStart: () => {
      dispatch(adminRouteChangeStarted())
    },
  }
}

function wrapPageWithLayout(PageComponent) {
  class AdminLayout extends React.PureComponent {

    static getInitialProps = async (nextArgs) => {
      if (PageComponent.getInitialProps)
        PageComponent.getInitialProps(nextArgs)

      const { pathname, query, store } = nextArgs

      if (query.auth_token) {
        store.dispatch(adminLoadedWithAuthToken(query.auth_token))
      }

      if (query.layout == "false") {
        store.dispatch(adminHideHeader())
      }

      store.dispatch(adminRouteChanged(pathname))

      return { pathname, query }
    }

    static propTypes = {
      activePage: PropTypes.string.isRequired,
      authorized: PropTypes.bool,
      breadcrumbs: ImmutablePropTypes.list,
      flash: ImmutablePropTypes.map,
      isLoading: PropTypes.bool,
      onFlashDismiss: PropTypes.func,
      onMount: PropTypes.func,
      onRouteChangeComplete: PropTypes.func,
      onRouteChangeStart: PropTypes.func,
      pathname: PropTypes.string,
      showLogo: PropTypes.bool,
      store: PropTypes.object,
    }

    componentDidMount() {
      const { segmentKey } = getConfig()
      registerInputEvents()
      this.props.onMount()
      initAnalytics(segmentKey)
      Router.onRouteChangeComplete = this.props.onRouteChangeComplete
      Router.onRouteChangeStart = this.props.onRouteChangeStart
    }

    componentWillUnmount() {
      Router.onRouteChangeComplete = null
      Router.onRouteChangeStart = null
    }

    render() {
      // TODO: make an api check for authorization before showing any admin content
      const {
        activePage,
        authorized,
        breadcrumbs,
        flash,
        isLoading,
        onFlashDismiss,
        showLogo,
      } = this.props

      return (
        <div className={ styles.component }>
          { !authorized &&
            <AdminUserAuth />
          }
          { authorized &&
            <div>
              <React.Fragment>
                <Header showLogo={ showLogo } activePage={ activePage } />
                <div className={ styles.subHeaderContainer }>
                  <Breadcrumbs items={ breadcrumbs } />

                  <Flash
                    level={ flash.get("level") }
                    message={ flash.get("message") }
                    onDismiss={ onFlashDismiss }
                    show={ flash.get("isVisible") }
                  />
                </div>
              </React.Fragment>

              <PageComponent store={ this.props.store } { ...this.props } />

              <LoadingCurtain
                layout="light"
                show={ isLoading }
                fullscreen
              />
            </div>
          }
        </div>
      )
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AdminLayout)
}

export default function withAdminLayout(PageComponent) {
  return withRedux(createAdminStore, wrapPageWithLayout(PageComponent))
}
