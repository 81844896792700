import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  showLogo: true,
})

const uiReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_HIDE_HEADER:
      return state.set("showLogo", false)

    default:
      return state
  }
}

export default uiReducer
