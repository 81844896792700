import { stripQueryString } from "highline/utils/url"
import * as CategoriesApi from "highline/api/admin/categories_api"
import * as UserAuthStorage from "highline/storage/user_auth_storage"
import * as AdminUsersApi from "highline/api/admin/users_api"
import AdminActionTypes from "highline/redux/admin/action_types"
import { userLoggedIn } from "highline/redux/admin/actions/users_actions"
import { fromJS } from "immutable"
import getConfig from "highline/config/application"
import { signIn } from "highline/utils/navigate"

const { google2faEnabled } = getConfig()

export const adminAuthenticated = () => ({
  type: AdminActionTypes.ADMIN_AUTHENTICATED,
})

export const googleLoginClientFailed = (response) => ({
  type: AdminActionTypes.ADMIN_USER_LOGIN_FAILED,
  response,
})

export const adminFlashDismissed = () => ({
  type: AdminActionTypes.ADMIN_FLASH_DISMISSED,
})

export const adminHideHeader = () => ({
  type: AdminActionTypes.ADMIN_HIDE_HEADER,
})

export const adminPageLoaded = (authenticationToken) => ({
  type: AdminActionTypes.ADMIN_PAGE_LOADED,
  authenticationToken,
})

export const adminRouteChanged = (pathname) => ({
  type: AdminActionTypes.ADMIN_ROUTE_CHANGED,
  pathname: stripQueryString(pathname),
})

export const adminRouteChangeStarted = () => ({
  type: AdminActionTypes.ADMIN_ROUTE_CHANGE_STARTED,
})

export const adminLoadedWithAuthToken = (authenticationToken) => ({
  type: AdminActionTypes.ADMIN_LOADED_WITH_AUTH_TOKEN,
  authenticationToken,
})

export const adminAuthFailed = (error) => ({
  type: AdminActionTypes.ADMIN_AUTH_FAILED,
  error,
})

export const adminRedirectFound = (redirectUrl) => ({
  type: AdminActionTypes.ADMIN_REDIRECT_FOUND,
  redirectUrl,
})

export const googleLoginClientAsync = (response) => (
  async (dispatch, getState) => {
    const authenticationToken = getState().getIn(["auth", "authenticationToken"])
    const googleAuthResponse = fromJS(response)
    const redirectUrl = getRedirectUrl(getState().get("currentPage"))
    try {
      const authResponse = await AdminUsersApi.getAuthCode(authenticationToken, googleAuthResponse)
      const data = authResponse.data
      dispatch(userLoggedIn(
        data.get("firstName"),
        data.get("lastName"),
        data.get("email"),
        data.get("externalId"),
        data.get("authenticationToken"),
        data.get("userId"),
      ))
      if (redirectUrl) {
        return dispatch(adminRedirectFound(redirectUrl))
      }
      dispatch(adminPageLoaded(authResponse.data.get("authenticationToken")))
      return dispatch(adminAuthenticated())
    } catch (error) {
      dispatch(adminAuthFailed(error))
      setTimeout(() => { throw error })
    }
  }
)

export const checkLoggedInAsync = () => (
  async(dispatch, getState) => {
    const auth = getState().get("auth")
    const authenticationToken = auth.get("authenticationToken") || UserAuthStorage.load().authenticationToken
    dispatch(adminPageLoaded(authenticationToken))
    if (authenticationToken) {
      await CategoriesApi.fetchAll(authenticationToken)
      dispatch(adminAuthenticated())
    } else if (!google2faEnabled) {
      signIn({ redirect_to: "admin-new" })
    }
  }
)

function getRedirectUrl(currentPage) {
  return currentPage.getIn(["query","redirect_to"], null)
}

