import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  isLoading: false,
})

const loadingReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_CATEGORY_REQUEST_STARTED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_DELETE_STARTED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_STARTED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_ALL_STARTED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_STARTED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_LOAD_FORM_OPTIONS_STARTED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_UPDATE_STARTED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_DELETE_STARTED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_ALL_STARTED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_STARTED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_UPDATE_STARTED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_REQUEST_STARTED:
    case AdminActionTypes.ADMIN_PRODUCTS_FETCH_STARTED:
    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_DELETE_STARTED:
    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_FETCH_STARTED:
    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_UPDATE_STARTED:
    case AdminActionTypes.ADMIN_RESET_VARIANT_GROUPS_REQUEST_STARTED:
    case AdminActionTypes.ADMIN_ROUTE_CHANGE_STARTED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_DELETE_STARTED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_FETCH_ALL_STARTED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_PREVIEW_STARTED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_STARTED:
    case AdminActionTypes.ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_STARTED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_DELETE_STARTED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_NEW_STARTED:
      return state.set("isLoading", true)

    case AdminActionTypes.ADMIN_CATEGORY_REQUEST_COMPLETED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_DELETE_FAILED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_DELETE_SUCCEEDED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_FAILED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_EDIT_EDUCATION_GROUP_PREVIEW_SUCCEEDED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_ALL_FAILED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_ALL_SUCCEEDED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_FAILED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_LOAD_FORM_OPTIONS_FAILED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_LOAD_FORM_OPTIONS_SUCCEEDED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_UPDATE_FAILED:
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_UPDATE_SUCCEEDED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_DELETE_SUCCEEDED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_ALL_FAILED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_ALL_SUCCEEDED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_FAILED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_UPDATE_FAILED:
    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_UPDATE_SUCCEEDED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_REQUEST_COMPLETED:
    case AdminActionTypes.ADMIN_PRODUCTS_FETCH_COMPLETED:
    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_DELETE_SUCCEEDED:
    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_UPDATE_FAILED:
    case AdminActionTypes.ADMIN_PRODUCT_CLASSIFICATIONS_UPDATE_SUCCEEDED:
    case AdminActionTypes.ADMIN_PRODUCT_SEARCH_FETCH_FAILED:
    case AdminActionTypes.ADMIN_PRODUCT_SEARCH_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_RESET_VARIANT_GROUPS_REQUEST_COMPLETED:
    case AdminActionTypes.ADMIN_ROUTE_CHANGED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_CREATE_FAILED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_CREATE_SUCCEEDED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_DELETE_FAILED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_DELETE_SUCCEEDED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_FETCH_ALL_FAILED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_FETCH_ALL_SUCCEEDED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_PREVIEW_FAILED:
    case AdminActionTypes.ADMIN_SIZE_CHARTS_PREVIEW_SUCCEEDED:
    case AdminActionTypes.ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_FAILED:
    case AdminActionTypes.ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_SUCCEEDED:
    case AdminActionTypes.ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_COMPLETED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_DELETE_FAILED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_DELETE_SUCCEEDED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_DELETE_COMPLETED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_NEW_FAILED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_NEW_SUCCEEDED:
    case AdminActionTypes.ADMIN_WALLET_PAYMENT_METHODS_NEW_COMPLETED:
      return state.set("isLoading", false)

    default:
      return state
  }
}

export default loadingReducer
