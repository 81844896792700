import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"
import { 
  buildCategoryBreadcrumbs,
  buildCategoryManageProgramsBreadcrumbs,
  buildNavigationItemBreadcrumbs,
  buildNonNestedBreadcrumbs,
  buildProductsBreadcrumbs,
  getStaticBreadcrumbs,
} from "highline/redux/admin/helpers/breadcrumbs_helper"

const initialState = fromJS({
  breadcrumbs: [],
})

const breadcrumbsReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_SIZE_CHARTS_FETCH_ALL_SUCCEEDED:
      return state.set("breadcrumbs",
        buildNonNestedBreadcrumbs("/admin-new/size-charts"))

    case AdminActionTypes.ADMIN_SIZE_CHARTS_FETCH_SUCCEEDED:
      return state.set("breadcrumbs",
        buildNonNestedBreadcrumbs("/admin-new/size-charts", false, action.sizeChart.get("id"), action.sizeChart.get("name")))

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_ALL_SUCCEEDED:
      return state.set("breadcrumbs",
        buildNonNestedBreadcrumbs("/admin-new/education-groups"))

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_FETCH_SUCCEEDED:
      return state.set("breadcrumbs",
        buildNonNestedBreadcrumbs("/admin-new/education-groups", false, action.educationGroup.get("id"), action.educationGroup.get("name")))

    case AdminActionTypes.ADMIN_EDUCATION_GROUPS_NEW_CLICKED:
      return state.set("breadcrumbs",
        buildNonNestedBreadcrumbs("/admin-new/education-groups", true))

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_ALL_SUCCEEDED:
      return state.set("breadcrumbs",
        buildNonNestedBreadcrumbs("/admin-new/classifications"))

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_SUCCEEDED:
      return state.set("breadcrumbs",
        buildNonNestedBreadcrumbs("/admin-new/classifications", false, action.classification.get("id"), action.classification.get("name")))

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_NEW_CLICKED:
      return state.set("breadcrumbs",
        buildNonNestedBreadcrumbs("/admin-new/classifications", true))

    case AdminActionTypes.ADMIN_NAVIGATION_ITEMS_FETCH_ALL_SUCCEEDED:
    case AdminActionTypes.ADMIN_NAVIGATION_ITEM_FETCH_SUCCEEDED:
      return state.set("breadcrumbs", buildNavigationItemBreadcrumbs(action.navigationItem))

    case AdminActionTypes.ADMIN_CATEGORIES_FETCH_ALL_SUCCEEDED:
    case AdminActionTypes.ADMIN_CATEGORY_FETCH_SUCCEEDED:
      return state.set("breadcrumbs", buildCategoryBreadcrumbs(action.category))

    case AdminActionTypes.ADMIN_CATEGORIES_MANAGE_PROGRAMS_LOAD_SUCCEEDED:
      return state.set("breadcrumbs", buildCategoryManageProgramsBreadcrumbs(action.category))

    case AdminActionTypes.ADMIN_PRODUCTS_PRODUCT_FETCH_SUCCEEDED:
      return state.set("breadcrumbs", buildProductsBreadcrumbs(action.data))

    case AdminActionTypes.ADMIN_FIT_PREFERENCES_OPTION_TYPES_FETCH_SUCCEEDED:
    case AdminActionTypes.ADMIN_WALLET_FETCH_ALL_PAYMENT_METHODS_SUCCEEDED:
      return state.set("breadcrumbs", getStaticBreadcrumbs("/admin-new", "Home"))

    case AdminActionTypes.ADMIN_PRODUCT_SEARCH_LOADED:
      return state.set("breadcrumbs", getStaticBreadcrumbs("/admin-new/product-search", "Index"))

    case AdminActionTypes.ADMIN_USER_STOCK_LOCATIONS_PAGE_LOADED:
      return state.set("breadcrumbs", getStaticBreadcrumbs("/admin-new/user-stock-locations", "Index"))

    default:
      return state
  }
}

export default breadcrumbsReducer
