import { List, fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  results: List(),
  term: "",
})

const productSearchReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_PRODUCT_SEARCH_FETCH_SUCCEEDED:
      return state.set("results", action.data.get("products"))

    case AdminActionTypes.ADMIN_PRODUCT_SEARCH_INPUT_CHANGED:
      return state.set(action.name, action.value)

    default:
      return state
  }
}

export default productSearchReducer
