import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"
import { getActivePage } from "highline/redux/admin/helpers/navigation_helper"

const initialState = fromJS({
  activePage: "",
})

const navReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_ROUTE_CHANGED:
      return state.set("activePage", getActivePage(action.pathname))

    default:
      return state
  }
}

export default navReducer
