import { combineReducers } from "redux-immutable"
import editReducer from "highline/redux/admin/reducers/categories/edit_reducer"
import indexReducer from "highline/redux/admin/reducers/categories/index_reducer"
import manageProgramsReducer from "highline/redux/admin/reducers/categories/manage_programs_reducer"

const rootReducer = combineReducers({
  edit: editReducer,
  index: indexReducer,
  managePrograms: manageProgramsReducer,
})

export default rootReducer
