import { fromJS } from "immutable"
import AdminActionTypes from "highline/redux/admin/action_types"

const initialState = fromJS({
  deleteModal: { isOpen: false, id: null, name: null },
  errors: {},
  list: [],
})

const indexReducer = (state = initialState, action)  => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_CLASSIFICATIONS_DELETE_MODAL_CLOSED:
      return state.set("deleteModal", initialState.get("deleteModal"))

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_FETCH_ALL_SUCCEEDED:
      return state.set("list", action.classifications)

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_DELETE_CLICKED:
      return state.set("deleteModal", fromJS({
        isOpen: true,
        id: action.classificationId,
        name: action.classificationName,
      }))

    case AdminActionTypes.ADMIN_CLASSIFICATIONS_DELETE_SUCCEEDED:
      return state.merge(fromJS({
        deleteModal: { isOpen: false },
        errors: {},
      }))

    default:
      return state
  }
}

export default indexReducer
